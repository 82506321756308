import config from "../config/config";

const axios = require('axios');

export const getUserByEmail = async (email) => {
    try {
        const response = await axios.get(`${config.backend_urls.users}`, { params: {
                email}});
        return response.data;
    } catch (error) {
        console.error(`error user by emailAddress ${email}: ${error}`);
        console.info(error.response.data.error);
        return error.response.data;
    }
}

export const getUserInfo = async () => {
    // We have a longstanding problem where occasionally, apparently
    // after a period of not being used, a login attempt which calls /userInfo
    // receives a HTTP 500. Typically the user doesn't have the network tab open
    // and therefore we have no information and its very difficult to troubleshoot.
    // Retry 3 times to try and avoid this in the short term. Print more info as part
    // of the visible error message if the 3 attempts result in failure
    const retries = 3;
    const delay = 2000;
    for (let i = 0; i < retries; i++) {
        try {
            const response = await axios.get(config.backend_urls.userInfo);
            return response.data;
        } catch (error) {
            if (i === retries - 1) {
                console.error(`error retrieving user info after ${i} attempts: ${error}`);
                throw error;
            }
            console.error(`error retrieving user info attempt ${i}. Retrying in ${delay} ms: ${error}`);
            await new Promise((resolve) => setTimeout(resolve, delay));
        }
    }
}


axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});
